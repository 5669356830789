<template>
	<v-container fluid>
		<v-col class="pa-1 pb-0">
			<v-row
				class="ma-0"
				align="center"
			>
				<v-col cols="12">
					<v-row class="ma-0 admin_table_area">
						<v-col
							cols="12"
							md="3"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-if="searchData_1"
								v-model="models.state"
								title="상담 상태"
								:select="searchData_1"
							/>
						</v-col>
						<v-col
							cols="12"
							md="3"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="models.gubun"
								title="문의 구분"
								:select="searchData_2"
							/>
						</v-col>
						<v-col
							cols="12"
							md="3"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="models.start_registDate"
								title="상담 의뢰일"
								date-picker
							/>
						</v-col>
						<v-col
							cols="12"
							md="3"
							class="pa-0"
						>
							<AdminItemsTableItem
								v-model="models.start_responseDate"
								title="상담 처러일"
								date-picker
							/>
						</v-col>
					</v-row>
				</v-col>
				<v-row class="ma-0">
					<v-col
						align="start"
						class="pt-0 md-pl-4"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="primary"
							@click="makeExcelFile"
						>
							<v-icon class="md-pr-2">mdi-download</v-icon>
							<p class="hidden-md-and-down">excel download</p>
						</v-btn>
					</v-col>
					<v-col
						align="end"
						class="pt-0"
					>
						<v-btn
							dark
							tile
							elevation="0"
							color="olive"
							@click="searchItems"
						>
							<v-icon class="pr-2">search</v-icon>
							<p>검색</p>
						</v-btn>
					</v-col>
				</v-row>
			</v-row>
		</v-col>
		<v-col class="pa-4 px-md-4 pt-0">
			<CommonDataTableGridType06
				v-model="model"
				:headers="headers"
				:desserts="desserts"
				:height="common_tableHeight(450)"
				:loading="loading"
				@open="onReadItem"
			/>
		</v-col>

		<AdminEstimateConsultRead
			v-if="readMode"
			:title="common_title"
			:dialog="readMode"
			:read-item="readItem"
			:width-code-items="widthCodeItems"
			@close="closeDialog"
		/>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import common from '@/mixins/common'
import admin from '@/mixins/admin'

export default {
	name: 'AdminEstimateConsult',
	mixins: [common, admin],
	data: () => ({
		mode: 'read',
		readMode: false,
		model: [],
		desserts: [],
		excelDesserts: [],
		readItem: {},
		headers: [
			// { text: '브랜드', value: 'brand_name', width: '80px' },
			// { text: '제품', value: 'goods_name', width: '80px' },
			// { text: '고객ID', value: 'consult_id', width: '80px' },
			// { text: '고객이름', value: 'login_name', width: '80px' },
			// { text: '상담의뢰일', value: 'check_date', width: '120px' },
			// { text: '등록자', value: 'regi_userid', width: '80px' },
			// { text: '등록일', value: 'regi_date', width: '120px' },
			// { text: '보기', value: 'data-read', width: '30px', sortable: false },

			{ text: '상담상태', value: 'consult_state', width: '60px' },
			{ text: '문의구분', value: 'product_kind', width: '80px' },
			{ text: '콘텐츠 URL', value: 'product_id', width: '140px' },
			{ text: '신청자명', value: 'login_name', width: '80px' },
			{ text: '문의인테리어점', value: 'business_name', width: '120px' },
			{ text: '상담 의뢰일', value: 'regi_date', width: '80px' },
			{ text: '상담 처리일', value: 'response_date1', width: '80px' },
			{ text: '보기', value: 'data-read', width: '30px', sortable: false },
			// { text: '수정', value: 'data-edit', width:'30px', sortable: false  },
		],
		searchData_1: [
			{
				text: '전체',
				value: '',
			},
			{
				text: '상담대기',
				value: '상담대기',
			},
			{
				text: '상담완료',
				value: '상담완료',
			},
		],
		searchData_2: [
			{
				text: '전체',
				value: '',
			},
			{
				text: '빠른견적',
				value: '빠른견적',
			},
			{
				text: '상품마스터',
				value: '상품마스터',
			},
			{
				text: '전문가집들이',
				value: '전문가집들이',
			},
			{
				text: '견적내기',
				value: '견적내기',
			},
			{
				text: '3D집들이',
				value: '3D집들이',
			},
			{
				text: '인테리어점 찾기',
				value: '인테리어점 찾기',
			},
			{
				text: '맞춤견적',
				value: '맞춤견적',
			},
		],
		models: {
			brand: null,
			goods: null,
			start_registDate: null,
			keyword: null,
			start_responseDate: null,
			//new
			state: null,
			kind: null,
			name: null,
			gubun: null,
		},
		loading: false,
	}),
	computed: {
		...mapGetters([
			// 제품 데이터
			'HOME_GET_INTERIOR_ESTIMATE_BRAND',
			'HOME_GET_INTERIOR_ESTIMATE_PRODUCT',
			// 평수 , 방 갯수 데이터
			'HOME_GET_INTERIOR_ESTIMATE_EXCLUSIVE_AREA',
			'HOME_GET_INTERIOR_ESTIMATE_ROOM_COUNT',
		]),
		widthCodeItems() {
			return this.HOME_GET_INTERIOR_ESTIMATE_EXCLUSIVE_AREA || []
		},
		// productItemsFilter() {
		// 	if (this.models.brand === null) return []
		// 	return this.common_filterItem(this.models.brand, this.HOME_GET_INTERIOR_ESTIMATE_PRODUCT, 'brand_code') || []
		// },
		askItemsFilter() {
			if (this.models.state === null) return []
			return this.common_findItem(this.models.state, this.searchData_1, 'value') || []
		},
	},
	created() {
		this.getList()
	},
	async mounted() {
		// 브랜드, 제품 데이터 가져오기
		await this.getData()
	},
	destroyed() {},
	methods: {
		...mapActions([
			// 브랜드, 제품 데이터 가져오기
			'HOME_ACT_INTERIOR_ESTIMATE_BRAND',
			'HOME_ACT_INTERIOR_ESTIMATE_PRODUCT',
			// 견적 상담 리스트 가져오기
			'ADMIN_ACT_ESTIMATE_CONSULT_ITEMS',
			// 전용 면적 리스트 가져오기
			'HOME_ACT_INTERIOR_ESTIMATE_EXCLUSIVE_AREA',
		]),
		async getData() {
			if (this.HOME_GET_INTERIOR_ESTIMATE_BRAND === null) await this.HOME_ACT_INTERIOR_ESTIMATE_BRAND() // 브랜드
			if (this.HOME_GET_INTERIOR_ESTIMATE_PRODUCT === null) await this.HOME_ACT_INTERIOR_ESTIMATE_PRODUCT() // 제품
			await this.HOME_ACT_INTERIOR_ESTIMATE_EXCLUSIVE_AREA()
		},
		async getList(params = {}) {
			this.loading = true
			await this.ADMIN_ACT_ESTIMATE_CONSULT_ITEMS(params).then(res => {
				console.log('값 받아오는값', res)
				this.desserts = res
				this.excelDesserts = res

				if (res.consult_state === null) {
					this.excelDesserts.forEach((item, index) => {
						item.consult_state = '상담대기중'
					})
				} else {
					this.excelDesserts.forEach((item, index) => {
						item.consult_state = '상담완료'
					})
				}

				if (res.product_kind === '3D집들이관리') {
					this.excelDesserts.forEach((item, index) => {
						item.product_id = `/housewarming/3d/detail/${res.product_id}`
					})
				} else if (res.product_kind === '상품마스터') {
					this.excelDesserts.forEach((item, index) => {
						item.product_id = `/product/detail/${res.product_id}`
					})
				} else {
					this.excelDesserts.forEach((item, index) => {
						item.product_id = `/housewarming/specialist/detail/${res.product_id}`
					})
				}

				this.loading = false
			})
		},
		searchItems() {
			const filterDate = this.common_inputDateFilter(this.models.start_registDate)
			const filterDate2 = this.common_inputDateFilter(this.models.start_responseDate)
			// const responseFilterDate = this.common_inputDateFilter(this.models.result_registDate)

			const params = {
				consult_state: this.models.state ? this.models.state.value : '',
				gubun: this.models.gubun ? this.models.gubun.value : '',
				regi_date_s: filterDate.startDate,
				regi_date_e: filterDate.endDate,
				response_date_s: filterDate2.startDate,
				response_date_e: filterDate2.endDate,
				name: this.models.name || '',
			}
			console.log('search', params)

			this.getList(params)
		},
		makeExcelFile() {
			this.common_create_excel(this.common_excel_data_convert(this.excelDesserts, this.headers), '견적상담')
		},
		onReadItem(item) {
			console.log('onReadItem', item)
			this.readItem = item
			this.readMode = true
		},
		closeDialog() {
			this.model = []
			this.readMode = false
			this.searchItems()
		},
	},
}
</script>
